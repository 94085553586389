import React from "react"
import RootElement from "./src/components/root-element"
// import { Helmet } from "react-helmet"
import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {/* <Helmet>
        <script
          key={"gatsby-cookie-consent-mode-cookiebot"}
          id="Cookiebot"
          async
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="1d920cb8-d500-4561-b66a-20493fe63aff"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet> */}
      <RootElement>{element}</RootElement>
    </>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
