exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biura-podrozy-js": () => import("./../../../src/pages/biura-podrozy.js" /* webpackChunkName: "component---src-pages-biura-podrozy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-narty-js": () => import("./../../../src/pages/narty.js" /* webpackChunkName: "component---src-pages-narty-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-wakacje-all-inclusive-js": () => import("./../../../src/pages/wakacje/all-inclusive/[...].js" /* webpackChunkName: "component---src-pages-wakacje-all-inclusive-js" */),
  "component---src-pages-wakacje-bulgaria-js": () => import("./../../../src/pages/wakacje/bulgaria.js" /* webpackChunkName: "component---src-pages-wakacje-bulgaria-js" */),
  "component---src-pages-wakacje-cypr-js": () => import("./../../../src/pages/wakacje/cypr.js" /* webpackChunkName: "component---src-pages-wakacje-cypr-js" */),
  "component---src-pages-wakacje-cypr-warszawa-chopina-js": () => import("./../../../src/pages/wakacje/cypr/warszawa-chopina.js" /* webpackChunkName: "component---src-pages-wakacje-cypr-warszawa-chopina-js" */),
  "component---src-pages-wakacje-dla-seniorow-js": () => import("./../../../src/pages/wakacje/dla-seniorow.js" /* webpackChunkName: "component---src-pages-wakacje-dla-seniorow-js" */),
  "component---src-pages-wakacje-egipt-js": () => import("./../../../src/pages/wakacje/egipt.js" /* webpackChunkName: "component---src-pages-wakacje-egipt-js" */),
  "component---src-pages-wakacje-grecja-js": () => import("./../../../src/pages/wakacje/grecja.js" /* webpackChunkName: "component---src-pages-wakacje-grecja-js" */),
  "component---src-pages-wakacje-hiszpania-js": () => import("./../../../src/pages/wakacje/hiszpania.js" /* webpackChunkName: "component---src-pages-wakacje-hiszpania-js" */),
  "component---src-pages-wakacje-js": () => import("./../../../src/pages/wakacje/[...].js" /* webpackChunkName: "component---src-pages-wakacje-js" */),
  "component---src-pages-wakacje-last-minute-js": () => import("./../../../src/pages/wakacje/last-minute/[...].js" /* webpackChunkName: "component---src-pages-wakacje-last-minute-js" */),
  "component---src-pages-wakacje-last-minute-tunezja-js": () => import("./../../../src/pages/wakacje/last-minute/tunezja.js" /* webpackChunkName: "component---src-pages-wakacje-last-minute-tunezja-js" */),
  "component---src-pages-wakacje-malta-js": () => import("./../../../src/pages/wakacje/malta.js" /* webpackChunkName: "component---src-pages-wakacje-malta-js" */),
  "component---src-pages-wakacje-polska-js": () => import("./../../../src/pages/wakacje/polska.js" /* webpackChunkName: "component---src-pages-wakacje-polska-js" */),
  "component---src-pages-wakacje-tunezja-js": () => import("./../../../src/pages/wakacje/tunezja.js" /* webpackChunkName: "component---src-pages-wakacje-tunezja-js" */),
  "component---src-pages-wakacje-turcja-js": () => import("./../../../src/pages/wakacje/turcja.js" /* webpackChunkName: "component---src-pages-wakacje-turcja-js" */),
  "component---src-pages-wakacje-wlochy-js": () => import("./../../../src/pages/wakacje/wlochy.js" /* webpackChunkName: "component---src-pages-wakacje-wlochy-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-country-prices-js": () => import("./../../../src/templates/countryPrices.js" /* webpackChunkName: "component---src-templates-country-prices-js" */)
}

